export const environment = {
    production: true,
    useMock: false,
    apiHost: 'https://rec-tfiberworkz-paula-frontend.azurewebsites.net',
    redirectUri: 'https://rec-tfiberworkz-paula-frontend.azurewebsites.net/authenticate',
    imageOptions: {
        annotationVersion: 3,
        imageWidth: 1600,
        imageHeight: 1200,
    }
};
